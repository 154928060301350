<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.deliver.addeditcampaign')" class="connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              <span>{{!deliverEmailIdExternal ? $t('connect.New_mail_campaign') : $t('connect.Edit_mail_campaign')}}</span>
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-right">
              <div v-if="checkPermission('connect.deliver.addedittemplate') && campaign.provider_mail_id" class="mt-1 mr-1 mt-xl-0 d-inline-block align-top">
                <CButton class="mr-2 ml-0 link" color="primary" @click="openSidebarRight('save_email_template', { template_type: 'deliver', template_source_id_external:  deliverEmailIdExternal, product_tag: null })">
                  <i class="fas fa-check mr-1"/>
                  <span>{{ $t('common.Save_email_template') }}</span>
                </CButton>
              </div>
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="fullscreenMode" size="is-small" @input="toggleFullscreenMode()">{{!deliverEmailIdExternal ? $t('connect.Fullscreen_email_editor') : $t('connect.Fullscreen_email')}}</b-switch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="loadingData">
            <CRow>
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="null"/>
              </CCol>
            </CRow>            
          </div>
          <div v-else>
            <CRow v-if="selectTemplateView" class="m-0">
              <CCol cols="12" lg="12" class="pb-0">
                <span>{{$t('connect.Pick_email_campaign_template')}}</span>
              </CCol>
              <CCol cols="3" lg="3">
                <navigationCard @click.native="selectTemplateView = false" path="" :cardTitle="$t('connect.Empty_campaign')" :cardDescription="$t('connect.Start_with_empty_campaign')" :cardIcon="null"/>
              </CCol>
              <CCol v-for="template in templates" v-bind:key="template.deliver_email_template_id_external" cols="3" lg="3">
                <navigationCard @click.native="getTemplateDetails(template.deliver_email_template_id_external)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null"/>
              </CCol>
            </CRow>
      
            <CRow v-if="!selectTemplateView" class="m-0">
              <CCol v-if="!fullscreenMode" cols="4" lg="4">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="campaign.subject" required was-validated :disabled="campaign.provider_mail_id !== null"/>
                  </CCol>
                </CRow>
                <CRow>        
                  <CCol cols="12" lg="12" class="pt-0">
                    <label>{{$t('common.Specified_for_groups')}}</label>
                    <multiselect
                      class="data_table"
                      v-model="campaign.target_group" 
                      :options="targetGroups" 
                      :multiple="false"
                      :hideSelected="true"
                      :closeOnSelect="true"                        
                      :placeholder="$t('common.Select_group')" 
                      :selectLabel="$t('common.Add_group')" 
                      :selectedLabel="$t('Added')"
                      track-by="group_id" 
                      label="title"
                      :disabled="campaign.provider_mail_id !== null">
                      <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                    </multiselect>          
                  </CCol>            
                </CRow>                           
                <CRow>           
                  <CCol cols="12" lg="12" class="pt-0">
                    <label>{{$t('connect.Sender_for_campaign')}}</label>
                    <multiselect
                      class="data_table"
                      v-model="campaign.sender" 
                      :options="senders"
                      :multiple="false"
                      :hideSelected="true"
                      :closeOnSelect="true"
                      :placeholder="$t('connect.Select_sender')" 
                      :selectLabel="$t('common.Add_sender')" 
                      :selectedLabel="$t('Selected')"
                      track-by="deliver_sender_id_external" 
                      label="label"
                      :disabled="campaign.provider_mail_id !== null">
                      <span slot="noResult">{{ $t('connect.No_senders_found') }}</span>
                    </multiselect>            
                  </CCol>            
                </CRow>
                <CRow>           
                  <CCol cols="12" lg="12" class="pt-0">
                    <label>{{$t('connect.Subtopic_for_mail_campaign')}}</label>
                    <multiselect
                      class="data_table"
                      v-model="campaign.subtopic" 
                      :options="subtopics"
                      :multiple="false"
                      :hideSelected="false"
                      :closeOnSelect="true"                  
                      :placeholder="$t('common.Select_subtopic')" 
                      :selectLabel="$t('common.Add_subtopic')" 
                      :selectedLabel="$t('Selected')"
                      :deselectLabel="$t('common.Remove_subtopic')"
                      track-by="subtopic_id_external" 
                      label="label"
                      :disabled="campaign.provider_mail_id !== null">
                      <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
                    </multiselect>            
                  </CCol>            
                </CRow>
                <!-- <CRow>           
                  <CCol cols="12" lg="12" class="pt-0">
                    <label>{{$t('connect.Status_campaign')}}</label>
                    <multiselect
                      class="data_table"
                      v-model="campaign.status" 
                      :options="statuses"
                      :multiple="false"
                      :hideSelected="true"
                      :closeOnSelect="true"
                      :placeholder="$t('common.Select_status')" 
                      :selectLabel="$t('common.Add_status')" 
                      :selectedLabel="$t('Selected')"
                      track-by="status_tag" 
                      label="label"
                      :disabled="campaign.provider_mail_id !== null">
                      <span slot="noResult">{{ $t('common.no_statuses_found') }}</span>
                    </multiselect>            
                  </CCol>            
                </CRow>  -->
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <b-switch class="mb-0 mt-1 mt-xl-0" v-model="campaign.schedule_campaign" size="is-small" @input="toggleScheduleCampaign()" :disabled="campaign.provider_mail_id !== null">{{ $t('connect.Schedule_campaign') }}?</b-switch>              
                    <div v-if="campaign.schedule_campaign === true" class="mt-2">                  
                      <p v-if="campaignScheduledDatetime !== null" class="mt-2 mb-2">{{$t('connect.Campaign_scheduled_for')}}: {{campaignScheduledDatetime | moment("DD-MM-YYYY HH:mm")}}</p>
                      <b-datetimepicker v-model="campaignScheduledDatetime" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions" :disabled="campaign.provider_mail_id !== null">
                        <template #left>
                          <CButton color="primary" @click="campaignScheduledDatetime = new Date();" :disabled="campaign.provider_mail_id !== null"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                        </template>
                        <template #right>
                          <CButton color="secondary" @click="campaignScheduledDatetime = null;" :disabled="campaign.provider_mail_id !== null"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                        </template>                    
                      </b-datetimepicker>
                    </div> 
                  </CCol>                  
                </CRow>
              </CCol>
              <CCol :cols="editorCols.cols" :lg="editorCols.lg">
                <div v-if="campaign.provider_mail_id">
                  <div v-html="campaign.html"></div>                  
                </div>
                <div v-else>
                  <!-- <label>{{$t('common.Content_editor')}}</label> -->
                  <EmailEditor ref="emailEditor"
                               :appearance="appearance"
                               :min-height="minHeight"
                               :project-id="projectId"
                               :locale="locale"
                               :tools="tools"
                               :options="options"/>
                </div>
              </CCol>
            </CRow>
            
            <CRow v-if="!selectTemplateView" class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="!campaign.deliver_email_id_external">
                  <CButton color="primary" @click="insertCampaign('draft');"><i class="fas fa-check mr-1"/>{{ $t('connect_deliver.Save_as_draft') }}</CButton>
                  <CButton color="primary" @click="insertCampaign('ready');"><i class="fas fa-paper-plane mr-1"/>{{ $t('connect_deliver.Send') }}</CButton>
                  <CButton color="secondary" @click="resetCampaignData(); showDeliverOverview();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
                </div>
                <div v-else>
                  <CButton v-if="!campaign.provider_mail_id" color="primary" @click="updateCampaign('draft');"><i class="fas fa-check mr-1"/>{{ $t('connect_deliver.Save_as_draft') }}</CButton>
                  <CButton v-if="!campaign.provider_mail_id" color="primary" @click="updateCampaign('ready');"><i class="fas fa-paper-plane mr-1"/>{{ $t('connect_deliver.Send') }}</CButton>
                  <CButton color="secondary" @click="showDeliverOverview();"><i class="fas fa-times mr-1"/>{{ campaign.provider_mail_id ? $t('close') : $t('cancel') }}</CButton>
                </div>
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import navigationCard from '@/components/common/navigationCard';
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';

export default {
  name: 'Campaign',
  components: {
    loadingSpinner,
    noPermission,
    navigationCard,
    QuickEdit,
    Multiselect,    
    EmailEditor
  },
  watch: {
    $props: {
      handler() {
        if(this.$route.params.id !== undefined) this.deliverEmailIdExternal = this.$route.params.id;
        // Get the campaign details
        this.getCampaignDetails();        
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,
      deliverEmailIdExternal: null,
      fullscreenMode: false,
      editorCols: { cols: 8, lg: 8},
      campaign: {
        status: {
          status_tag: null
        }
      },
      targetGroups: [],
      statuses: [],
      senders: [],
      subtopics: [],
      templates: [],
      selectTemplateView: false,
      campaignScheduledDatetime: null,
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      emailEditorCols: null,
      emailCampaignCols: null,
      minHeight: '1000px',
      locale: 'en',
      projectId: 50935,
      tools: {
        image: { enabled: true } // Disable image tool
      },
      options: {},
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
    };
  },
  methods: {
    getCampaignDetails() {
      if(this.deliverEmailIdExternal !== null) {
        // Start the loader
        this.loadingData = true;
        // Get the email campaign details
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/email/' + this.deliverEmailIdExternal)
        .then(res => {
          this.campaign = res.data.data;
          // Set the campaignScheduledDatetime value if the campaign is scheduled
          if(this.campaign.scheduled !== null) this.campaignScheduledDatetime = new Date(this.campaign.scheduled);

          if(!this.campaign.provider_mail_id) {
            // Get the mailDesign of the campaign
            let mailDesign = JSON.parse(this.campaign.json);                    
            // Stop the loader
            this.loadingData = false;
            // Wait for 500ms and load the mailDesign into the editor
            setTimeout(function(){
              this.$refs.emailEditor.editor.loadDesign(mailDesign);
            }.bind(this), 500);
          } else {
            // Stop the loader
            this.loadingData = false;
          }
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the data
        this.resetCampaignData();
        // Start the loader
        this.loadingData = true;
        // Get the available templates
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/templates/list')
        .then(res => {      
          this.templates = res.data.data;
          // Update the selectTemplateView value if there are templates available
          if(this.templates.length > 0) this.selectTemplateView = true;
          // Stop the loader
          this.loadingData = false;
        })
        .catch(err => {
          console.error(err); 
        });
      }
    },
    insertCampaign(campaignStatus) {
      let params = {};
      params.emailData = this.campaign;
      params.emailData.json = null;
      params.emailData.html = null;
      params.emailData.status = { status_tag: campaignStatus };

      // Set scheduled datetime if campaign is scheduled
      if(params.emailData.schedule_campaign === true && this.campaignScheduledDatetime !== null) {
        params.emailData.scheduled = this.$luxon(this.campaignScheduledDatetime.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }
      
      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.emailData.json = JSON.stringify(design);
        });
  
        this.$refs.emailEditor.editor.exportHtml((data) => {
          params.emailData.html = data.html;
        });
      }

      let subject = params.emailData.subject;
      let status_tag = params.emailData.status.status_tag;
      let sender = params.emailData.sender;
      let target_group = params.emailData.target_group;
    
      // Set 500ms timeout to make sure that the JSON and HTML data is added to the emailParams
      setTimeout(function(){
        // Check if the necessary params are available
        if(subject && status_tag && sender && target_group) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/connect/deliver/email', params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('connect.Campaign_added'), type: 'is-success', duration: 3000 });
            // Reset the data
            this.resetCampaignData();
            // Return to the campaign overview
            this.showDeliverOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    updateCampaign(campaignStatus) {
      let params = {};
      params.emailData = this.campaign;
      params.emailData.json = null;
      params.emailData.html = null;
      params.emailData.status = { status_tag: campaignStatus };

      // Set scheduled datetime if campaign is scheduled
      if(params.emailData.schedule_campaign === true && this.campaignScheduledDatetime !== null) {
        params.emailData.scheduled = this.$luxon(this.campaignScheduledDatetime.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.emailData.scheduled = null;
      }
      
      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.emailData.json = JSON.stringify(design);
        });
  
        this.$refs.emailEditor.editor.exportHtml((data) => {
          params.emailData.html = data.html;
        });
      }

      let subject = params.emailData.subject;
      let status_tag = params.emailData.status.status_tag;
      let sender = params.emailData.sender;
      let target_group = params.emailData.target_group;
    
      // Set 500ms timeout to make sure that the JSON and HTML data is added to the emailParams
      setTimeout(function(){
        // Check if the necessary params are available
        if(subject && status_tag && sender && target_group) {
          axios.put(process.env.VUE_APP_API_URL + '/v1/connect/deliver/email/' + this.deliverEmailIdExternal, params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('connect.Campaign_updated'), type: 'is-success', duration: 3000 });
            // Return to the campaign overview
            this.showDeliverOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    getTemplateDetails(deliverEmailTemplateIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/template/' + deliverEmailTemplateIdExternal)
      .then(res => {
        // Get the mailDesign of the campaign
        let mailDesign = JSON.parse(res.data.data.json);
        // Update the selectTemplateView value
        this.selectTemplateView = false;
        // Wait for 500ms and load the mailDesign into the editor
        setTimeout(function(){
          this.$refs.emailEditor.editor.loadDesign(mailDesign);
        }.bind(this), 500);        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }, 
    getSenders () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/senders/multiselect')
      .then(res => {      
        this.senders = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getDeliverStatuses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/statuses/multiselect')
      .then(res => {
        this.statuses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    toggleFullscreenMode() {
      (this.fullscreenMode) ? this.editorCols = { cols: 12, lg: 12 } : this.editorCols = { cols: 8, lg: 8 };
    },       
    toggleScheduleCampaign() {
      if(this.campaign.schedule_campaign === false) {
        this.campaignScheduledDatetime = null;
      } else {
        this.campaignScheduledDatetime = new Date();
      }
    },
    showDeliverOverview() {
      // Set tab to activate values in localStorage
      localStorage.setItem('deliverTabToActivate', 'mail')
      localStorage.setItem('mailTabToActivate', 'campaigns');
      // Return to the deliver overview      
      this.$router.push({path: '/connect/deliver'});
    },   
    resetCampaignData() {
      this.campaign = {
        deliver_email_id_external: null,
        provider_mail_id: null,
        subject: null,
        sender: null,
        target_group: null,
        status: {
          label: "Draft",
          status_tag: "draft"          
        },        
        subtopic: null,
        schedule_campaign: false,     
        scheduled: null,
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getTargetGroups();
    this.getDeliverStatuses();
    this.getSenders();
    this.getSubtopics();
  }
};
</script>

<style>
#bar {
  flex: 1;
  background-color: #40B883;
  color: #FFF;
  padding: 10px;
  display: flex;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #FFF;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}

.unlayer-editor iframe {
  min-height: 1000px !important;
}

.unlayer-editor iframe .blockbuilder-branding {
  display: none;
}
</style>